body {
    padding-top: 40px;
    padding-bottom: 40px;
    text-align: center;
}

.IhmLoginForm {
    max-width: 400px;
    padding: 15px;
    margin: 0 auto;
}

.IhmChangePasswordForm {
    max-width: 400px;
    margin: 0 auto;
}

.IhmSignupForm {
    max-width: 400px;
    margin: 0 auto;
}

.SignupInfoMessage {
    padding: 20px;
}

.amazon-logo {
    width: 70%;
}

.form-group {
    margin: 8px;
    font-size: 18px;
    width: 90%;
    /*color: black;*/
    padding: 11px;
    box-sizing: border-box;
    border-radius: 6px;
    border: 1px solid #ccc;
}

.form-control:focus {
    outline: none;
    border-color: #e77600;
    box-shadow: 0 0 3px 2px rgba(228, 121, 17, .5);
}

.btn-yellow {
    background-image: linear-gradient(to bottom, #f7dfa5 0px, #f0c14b 100%);
    background-repeat: repeat-x;
    border-color: #a88734 #9c7e31 #846a29;
}

.btn-yellow:hover {
    background: rgba(0, 0, 0, 0) linear-gradient(to bottom, #f5d78e, #eeb933) repeat scroll 0 0;
}

.btn-yellow:active {
    border-color: #a88734 #9c7e31 #9c7e31;
    background: #f0c14b none repeat scroll 0 0;
}

.btn-grey {
    background: rgba(0, 0, 0, 0) linear-gradient(to bottom, #f7f8fa, #e7e9ec) repeat scroll 0 0;
    border-color: #ADB1B8 #A2A6AC #8D9096;
    color: #000000;
}

.btn-grey:hover {
    background: rgba(0, 0, 0, 0) linear-gradient(to bottom, #e7eaf0, #d9dce1) repeat scroll 0 0;
    border-color: #a2a6ac #979aa1 #82858a;
}

.btn-grey:active {
    border-color: #adb1b8 #a2a6ac #a2a6ac;
    background-color: #dcdfe3;
}

.error-message {
    color: #ff0000;
}

.success-message {
    color: #198754;
}

.changePassword-Container {
    display: flex;
    justify-content: center;
}

.input-conditions {
    width: 90%;
    margin-left: 8px;
    padding-left: 11px;
    text-align: left;
}